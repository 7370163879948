body {
  background-color: #f6f3f2;
}

.container {
  max-width: 1536px;
  overflow: hidden;
  padding: 0px;
}


::-webkit-scrollbar {
  background: #f6f3f2;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2.5px;
}

.color-blue {
  color: #002c5f;
}

.white-bg-color {
  background-color: #ffffff;
}

.yellow-bg-color {
  background-color: #FAF4E5;
}

.terms-heading {
  font-size: 6rem;
  letter-spacing: normal;
  line-height: 115%;
  text-align: left;
  margin-bottom: 1em;
}

.bold {
  font-weight: bold;
}

.headingContainer {
  top: 0;
  width: 100%;
  position: fixed;
  max-width: 1536px;
  margin-top: 160px;
  margin-bottom: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out;
}

.termsContainer {
  top: 0;
  width: 100%;
  max-width: 1536px;
  margin-top: 160px;
  margin-bottom: 150px;
  margin-left: 8.33%;
  text-align: left;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out;
}

.termsContainer>h4 {
  margin-top: 96px;
  font-weight: bold;
  font-family: HyundaiSansHead-Regular;
}

.termsContainer>h5 {
  width: 80%;
  margin-top: 1em;
  font-weight: bold;
  font-family: HyundaiSansHead-Regular;
}

.termsContainer>table {
  width: 80%;
  font-family: HyundaiSansHead-Regular;
  margin-top: 1em;
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 2px solid black;
  text-align: left;
  padding: 12px;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

a {
  color: #007bff;
}

.table-container {
  width: 80%;

}

.termsContainer>p {
  width: 80%;
  font-size: 1.125rem;
  letter-spacing: normal;
  line-height: 135%;
  font-family: HyundaiText-Regular;
}

.termsContainer>li {
  width: 80%;
  font-size: 1.125rem;
  letter-spacing: normal;
  line-height: 135%;
  font-family: HyundaiText-Regular;
}

.hyundai-heading {
  font-family: HyundaiText-Bold;
  font-size: 90px;
  line-height: 128px;
  color: #000000;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s 0.3s, opacity 0.5s linear;
}

.fade {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.1s linear;
}

.subheading {
  font-family: HyundaiSansHead-Regular;
  font-size: 48px;
  line-height: 58px;
  color: #000000;
  position: sticky;
}

.desc {
  font-family: HyundaiText-Regular;
  width: 58%;
  font-size: 20px;
  line-height: 26px;
  margin-top: 0;
  position: sticky;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: column;
}

.home-grid {
  margin-top: 35em;
}

.collection-grid {
  margin-top: 25em;
}

.footer {
  background-color: #1c1b1b;
  color: #fff;
  flex-grow: 0;
  font-family: var(--font-copy);
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 135%;
  padding-bottom: 75px;
  padding-top: 60px;
  width: 100%;
  z-index: 2;
}

.links {
  padding-right: 16px;
  padding-left: 16px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #8e8d8d;
  font-family: HyundaiSansHead-Medium;
}

.socials img:hover {
  filter: brightness(0) invert(1);
  transition: filter 0.3s ease-in;
}

.links span:hover {
  color: white;
  transition: color 0.3s ease-in;
}

.terms-links {
  opacity: .5;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 1px;
  transition: color .3s ease-out;
  font-family: HyundaiSansHead-Medium;
}


.terms-links:hover {
  color: #8e8d8d;
}

.socials {
  padding-right: 10px;
  padding-left: 10px;
}

.fb {
  width: 10%;
}

.x {
  width: 10%;
}

.Button-div {
  text-align: center;
  margin-top: 5em;
  margin-bottom: 5em;
}

.show-more {
  padding: 20px 36px 20px 36px;
  color: #002c5f;
  font-size: 16px;
  line-height: 16px;
  font-family: HyundaiSansHead-Medium;
  background-color: transparent;
  border: 2px solid #002c5f
}

.show-more:hover {
  border: 2px solid #004489;
  color: #004489;
  transition: border 0.2s ease-out 0s;
  transition: color 0.2s ease-out 0s;
}

.footer-desc {
  margin-left: 8.333333%;
  width: 50%;
}

@media (max-width: 576px) {
  .headingContainer {
    margin-top: 110px;
  }

  .terms-heading {
    font-size: 4rem;
    width: 90%;
  }

  .termsContainer>p {
    width: 90%;
    font-size: .875rem;
  }

  .termsContainer>h4 {
    margin-top: 60px;
  }

  .termsContainer>h5 {
    width: 90%;
  }

  .termsContainer>li {
    width: 90%;
    font-size: .875rem;
  }

  .termsContainer {
    margin-left: 3%;
  }

  .termsContainer>table {
    width: 80%;
    font-family: HyundaiSansHead-Regular;
    margin-top: 1em;
    font-weight: bold;
  }

  .table-container {
    width: 90%;
    overflow-x: auto;
    margin-top: 20px;
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 2px solid black;
    text-align: left;
    padding: 2px;
    font-size: 16px;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .collection-grid {
    margin-top: 14em;
  }

  .home-grid {
    margin-top: 26em;
  }

  .gallery-grid {
    margin-top: 25em;
  }

  .hyundai-heading {
    font-size: 42px;
    line-height: normal;
  }

  .subheading {
    font-size: 22px;
  }

  .footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer-desc {
    margin-left: 12px;
    width: 85%;
  }

  .links {
    padding-left: 0px;
  }

  .desc {
    width: 90%;
    font-size: 18px;
    line-height: 24.3px;
  }

  .fb {
    width: 5%;
  }

  .x {
    width: 5%;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .container {
    max-width: 1536px;
    overflow: hidden;
    padding: 0px;
  }

  .headingContainer {
    margin-top: 130px;
  }

  .home-grid {
    margin-top: 40em;
  }

  .desc {
    width: 75%;
  }

}
