.navbar-container {
  width: 100%;
  height: 80px;
  padding: 20px;
  position: fixed;
  z-index: 300;
  display: flex;
  align-items: center;
  max-width: 1536px;
  background-color: #f6f3f2;
  transition: transform 0.3s ease-in-out;
}

.mycollection {
  color: #003984;
  font-size: 20px;
  font-weight: 500;
  font-family: HyundaiSansHead-Medium;
}

.hyundai-logo {
  width: 12%;
  height: 100%;
}

.visible {
  transform: translateY(0);
}

.hidden {
  transform: translateY(-100%);
}

.product-link {
  align-items: center;
  color: black;
  cursor: pointer;
  display: flex;
  font-family: HyundaiSansHead-Regular;
  font-size: 1rem;
  font-weight: 500;
  gap: 4px;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: end;
  letter-spacing: .4px;
  line-height: 100%;
  white-space: nowrap;
  text-decoration: none;
}

.product-link:hover {
  color: #004489;
  transition: color 0.2s ease-in
}



@media (max-width: 480px) {
  .navbar-container {
    padding-top: 20px;
    align-items: flex-start;
  }

  .mycollection {
    font-size: 17px;
  }

  .hyundai-logo {
    width: 10%;
  }
}
