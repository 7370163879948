.image-list {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
  grid-gap: 0px 20px;
  grid-auto-rows: 10.6px;
}

.image-list img {
  width: 350px;
  aspect-ratio: 4/5;
  object-fit: cover;
}

.zoom-effect {
  overflow: hidden;
}

.zoom-effect img {
  transition: transform 0.5s ease;
}

.zoom-effect:hover img {
  transform: scale(1.1);
}

.fade-slide-up {
  opacity: 0;
  transform: translateY(150px);
  transition: opacity 0.7s ease, transform 0.3s ease;
}

.fade-slide-up.fade-slide-up-enter-done {
  opacity: 1;
  transform: translateY(0);
}

/* Define the scroll-effect animation */
@keyframes scroll-effect {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-0px);
  }
}

/* Apply the animation to the element when the class is added */
#scrollGrid1.scroll-animation-active {
  animation: scroll-effect 1s linear infinite;
}

#scrollGrid2.scroll-animation-active {
  animation: scroll-effect 1s linear infinite;
}

/* Apply the common styling for scroll-effect-container */
.scroll-effect-container {
  transition: transform 1s ease;
  will-change: transform;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 576px) {

  .image-list {
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    grid-auto-rows: 10.6px;

    /* grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
      grid-gap: 0px 0px;
      grid-auto-rows: 11px; */
  }

  .image-list img {
    aspect-ratio: 4/5;
    width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .image-list {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-auto-rows: 10.5px;
  }

  .image-list img {
    aspect-ratio: 4/5;
    width: 195px;
  }

}
