.gap8 {
  gap: 8px;
}

.gap4 {
  gap: 4px;
}

.gap10 {
  gap: 10px;
}

.gap6 {
  gap: 6px;
}

.gap20 {
  gap: 20px;
}

.hide {
  display: none !important;
}


.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-justify {
  display: flex;
  justify-content: center;
}

.width-4 {
  width: 4%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.width-80 {
  width: 80%;
}

.width-85 {
  width: 85%;
}

.width-90 {
  width: 90%;
}

.height-10 {
  height: 10%;
}

.height-100 {
  height: 100%;
}

.height-20 {
  height: 20%;
}

.height-40 {
  height: 40%;
}

.height-70 {
  height: 70%;
}

.height-80 {
  height: 80%;
}

.height-90 {
  height: 90%;
}

.margin-top4 {
  margin-top: 4px;
}

.margin-top10 {
  margin-top: 10px;
}

.margin-top12 {
  margin-top: 12px;
}

.margin-top16 {
  margin-top: 16px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-top30 {
  margin-top: 30px;
}

.margin-top40 {
  margin-top: 40px;
}

.margin-top70 {
  margin-top: 70px;
}

.margin-top135 {
  margin-top: 135px;
}

.margin-top110 {
  margin-top: 110px;
}

.margin-top150 {
  margin-top: 150px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.padding-top12 {
  padding-top: 12px;
}

.padding-top16 {
  padding-top: 16px;
}

.padding-top10 {
  padding-top: 20px;
}

.padding-top25 {
  padding-top: 25px;
}

.padding-top8 {
  padding-top: 8px;
}

.padding-left-right15 {
  padding-left: 15%;
  padding-right: 15%;
}

.greenAccent {
  color: #6fac43;
}

.boxRadius8 {
  border-radius: 8px;
}

.boxRadius4 {
  border-radius: 4px;
}

.boxRadius6 {
  border-radius: 6px;
}

.borderRadius50 {
  border-radius: 50%;
}

.margin-top6 {
  margin-top: 6px;
}

.lightBorder {
  border: 1px solid #eeeeee !important;
}

.light2Border {
  border: 1px solid #e2e2e2;
}

.opacity50 {
  opacity: 0.5;
}

.font-size18 {
  font-size: 18px;
}

.font-size16 {
  font-size: 16px;
}

.font-size14 {
  font-size: 14px;
}

.font-size12 {
  font-size: 12px;
}

.font-size10 {
  font-size: 10px;
}

.font-size8 {
  font-size: 8px;
}

.font-size9 {
  font-size: 9px;
}

.boxShadow8 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.boxShadow15 {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}



.stdFontStyle {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
}

.letterSpacing015 {
  letter-spacing: 0.0015rem;
}

.letterSpacing05 {
  letter-spacing: 0.005rem;
}

.letterSpacing025 {
  letter-spacing: 0.0025rem;
}

.letterSpacing0125 {
  letter-spacing: 0.0125em;
}

.letterSpacing001 {
  letter-spacing: 0.001em;
}

.letterSpacing004 {
  letter-spacing: 0.004em;
}

.letterSpacing02 {
  letter-spacing: 0.2px;
}

.font-weight400 {
  font-weight: 400;
}

.font-weight500 {
  font-weight: 500;
}

.font-weight600 {
  font-weight: 600;
}

.font-weight700 {
  font-weight: 700;
}

.font-weight800 {
  font-weight: 800;
}

.line-height21 {
  line-height: 21px;
}

.line-height19 {
  line-height: 19px;
}

.line-height16 {
  line-height: 16px;
}

.line-height13 {
  line-height: 13px;
}

.line-height14 {
  line-height: 14px;
}

.line-height12 {
  line-height: 12px;
}

.line-height10 {
  line-height: 10px;
}

.line-height6 {
  line-height: 6px;
}

.margin-left16 {
  margin-left: 16px;
}

.margin-left20 {
  margin-left: 20px;
}

.margin-left12 {
  margin-left: 12px;
}

.margin-right12 {
  margin-right: 12px;
}

.margin-right16 {
  margin-right: 16px;
}

.margin-left24 {
  margin-left: 24px;
}

.margin-top24 {
  margin-top: 24px;
}

.margin-top16 {
  margin-top: 16px;
}

.margin-top18 {
  margin-top: 18px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-bottom16 {
  margin-bottom: 16px;
}

.margin-bottom18 {
  margin-bottom: 18px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.margin-bottom24 {
  margin-bottom: 24px;
}

.margin-left24 {
  margin-left: 24px;
}

.margin-top12 {
  margin-top: 12px;
}

.margin-top10 {
  margin-top: 10px;
}

.margin-top5 {
  margin-top: 5px;
}

.margin-top14 {
  margin-top: 14px;
}

.margin-top24 {
  margin-top: 24px;
}

.margin-y-auto {
  margin: 0 unset;
}

.padding-left16 {
  padding-left: 16px;
}

.padding-right16 {
  padding-right: 16px;
}

.padding-top16 {
  padding-top: 16px;
}

.padding-bottom16 {
  padding-bottom: 16px;
}

.padding-top20 {
  padding-top: 20px;
}

.padding-top24 {
  padding-top: 24px;
}

.py-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-left9_5 {
  padding-left: 9.5px;
}

.padding-top9_5 {
  padding-left: 9.5px;
}

.padding-top11_5 {
  padding-left: 11.5px;
}

.padding-right9_5 {
  padding-right: 9.5px;
}

.padding-right13 {
  padding-right: 13px;
}

.padding-left13 {
  padding-left: 13px;
}

.padding-left-right16 {
  padding-left: 16px;
  padding-right: 16px;
}

.textColorWhite {
  color: White;
}

.textColorBlack {
  color: black;
}

.borderRadiusTop {
  border-radius: 8px 8px 0px 0px;
}

.borderRadiusBottom {
  border-radius: 0px 0px 8px 8px;
}

.borderRadiusBottom12 {
  border-radius: 0px 0px 12px 12px;
}

.padding16 {
  padding: 16px;
}

.padding1 {
  padding: 1px;
}

.padding-top1 {
  padding-top: 1px;
}

.margin16 {
  margin: 16px;
}

.positionRelative {
  position: relative;
}

.positionFixed {
  position: fixed;
  top: 10px;
  right: 10px;
}

.positionAbsolute {
  position: absolute;
  top: 5px;
  right: 10px;
}

.display-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap
}

.justify-content-end .m-auto {
  margin: auto;
}

.mlr-auto {
  margin-left: auto;
  margin-right: auto;
}

.mtb-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.colorLightGrey {
  color: #7B808B;
}

.backLightGrey {
  background: #e2e2e2;
}

.colorLightGrey5 {
  color: #f5f5f5;
}

.backLightGrey5 {
  background: #f5f5f5;
}

.padding-top8 {
  padding-top: 8px;
}

.padding-bottom8 {
  padding-bottom: 8px;
}

.padding-left8 {
  padding-left: 8px;
}

.padding-right8 {
  padding-right: 8px;
}

.padding8 {
  padding: 8px;
}

.margin-top4 {
  margin-top: 4px;
}

.margin-left4 {
  margin-left: 4px;
}

.margin-top8 {
  margin-top: 8px;
}

.margin-bottom8 {
  margin-bottom: 8px;
}

.margin-bottom0 {
  margin-bottom: 0px;
}

.margin-left8 {
  margin-left: 8px;
}

.margin-right8 {
  margin-right: 8px;
}

.margin8 {
  margin: 8px;
}

.padding-bottom12 {
  padding-bottom: 12px;
}

.padding-left12 {
  padding-left: 12px;
}

.padding-right12 {
  padding-right: 12px;
}

.padding-bottom14 {
  padding-bottom: 14px;
}

.padding-top14 {
  padding-top: 14px;
}

.padding12 {
  padding: 12px;
}

.backgroundWhite {
  background: white;
}

.cursorPointer {
  cursor: pointer;
}

.colorDark7 {
  color: #4d5557;
}

.colorDark6 {
  color: #363b3d;
}

.colorDark8 {
  color: #656e71;
}

.colorDark9 {
  color: #7c878b;
}

.colorDark1 {
  color: #111313;
}

.colorDark2 {
  color: #080c0c;
}

.colorDark13 {
  color: #959393;
}

.colorAccent5 {
  color: #179778;
}

.colorAccent4 {
  color: #127960;
}

.colorAccent3 {
  color: #0e5b48;
}

.colorGreen1 {
  color: #5a9a07;
}

.colorRed4 {
  color: #d92e30;
}

.colorAccent9 {
  color: #dfece9;
}

.backAccent3 {
  background-color: #0e5b48;
}

.backAccent4 {
  background-color: #127960;
}

.backAccent9 {
  background-color: #dfece9;
}

.backgroundLightBrown {
  background: #f8f8f8;
}

.overWriteInputButtonCSS {
  border-width: 0px;
  outline: none;
}

.width-97 {
  width: 97%;
}

.width-100 {
  width: 100%;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-items-left {
  align-items: left;
}

.textCenter {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.textWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.white-space-noWrap {
  white-space: nowrap;
}

.scrollHorizontal {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollHorizontal::-webkit-scrollbar {
  display: none;
}

.flex50 {
  flex: 50%;
}

.flex100 {
  flex: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-d-col {
  flex-direction: column;
}

.flex-d-row {
  flex-direction: row;
}

.linear-gradient1 {
  background: linear-gradient(294.04deg, #e1e3e3 1.44%, #dfece9 93.06%);
}

.linear-gradient2 {
  background: linear-gradient(293.5deg, #293a3a 47.87%, #179778 123.65%);
}

.padding-bottom5 {
  padding-bottom: 5px;
}

.padding-bottom4 {
  padding-bottom: 4px;
}

.padding-bottom2 {
  padding-bottom: 2px;
}

.padding-top4 {
  padding-top: 4px;
}

.padding-top5 {
  padding-top: 5px;
}

.padding-top2 {
  padding-top: 2px;
}

.padding-left4 {
  padding-left: 4px;
}

.padding-right4 {
  padding-right: 4px;
}

.padding4 {
  padding: 4px;
}

.colorAccent2 {
  color: #093c30;
}

.scroll-horizontal {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scroll-horizontal::-webkit-scrollbar {
  display: none;
}

.backgroundDarkGrey {
  background: #293a3a;
}

.backgroundColorF5 {
  background: #f5f5f5;
}

.padding-left21 {
  padding-left: 21px;
}

.padding-right19 {
  padding-right: 19px;
}

.padding-bottom20 {
  padding-bottom: 20px;
}

.padding-bottom24 {
  padding-bottom: 24px;
}

.padding-left-right24 {
  padding-left: 24px;
  padding-right: 24px;
}

.text-transparent {
  color: transparent;
}

.m0 {
  margin: 0;
}

.p0 {
  padding: 0;
}

.padding-top-unset {
  padding-top: unset !important;
}

.first-letter-caps {
  text-transform: capitalize;
}

.line-height18 {
  line-height: 18px;
}

.gap2 {
  gap: 2px;
}

.gap4 {
  gap: 4px;
}

.gap5 {
  gap: 5px;
}

.gap8 {
  gap: 8px;
}

.gap6 {
  gap: 6px;
}

.gap12 {
  gap: 12px;
}

.gap14 {
  gap: 14px;
}

.gap16 {
  gap: 16px;
}

.gap17 {
  gap: 17px;
}

.gap19 {
  gap: 19px;
}

.gap10 {
  gap: 10px;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.colorSecondary8 {
  color: #b8bdbd;
}

.va-abaseline {
  vertical-align: baseline;
}

.asterisk {
  color: #d5261b;
  margin-top: -2px;
}

.col4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.flex1 {
  flex: 1;
}

.OverThePageCSS {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  z-index: 999;
  width: 100%;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.col7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.header-height-61 {
  height: 61px;
}

.d-inline-grid {
  display: inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
}

.gap2 {
  gap: 2px;
}

.colorDark5 {
  color: #1f2223;
}

.colorBlue1 {
  color: #196599;
}

.colorDark4 {
  color: #1c1e1f;
}

.display-block {
  display: block;
}

.textColorBlue {
  color: #2081c3;
}

.textColorSuccess {
  color: #1d7330;
}

.textColorError {
  color: #d42015;
}

.backColorAccent5 {
  background-color: #e1e3e3;
}

.border-radius50 {
  border-radius: 50%;
}

.colorDark12 {
  color: #666760;
}

.width-5 {
  width: 5%;
}

.width-50 {
  width: 50%;
}

.colorBlack700 {
  color: #121212;
}

.colorBlack333 {
  color: #333333;
}

.colorGrey4848 {
  color: #484848;
}

.colorBlack212 {
  color: #212e2e;
}

.colorGreen269 {
  color: #269940;
}

.flex-column {
  flex-direction: column;
}

.backAlertWarning4 {
  background-color: #e89c0d;
}

.alertWarning4 {
  color: #e89c0d;
}

.alertWarning5 {
  color: #ae750a;
}

.height-40p {
  height: 40px;
}

.colorDark3 {
  color: #181a1b;
}

.colorDark10 {
  color: #1c1e1f;
}

.colorDark11 {
  color: #d42015;
}

.colorDark-background-color-red {
  background-color: #d42015;
}

.dont-show-empty-div:empty {
  display: none;
}

.input-style-error {
  border: 1px solid #d42015;
}

.input-error-style {
  padding-top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.004em;

  /* Alert/Danger/4 */

  color: #d42015;
}

.input-style-disable {
  background: #eeeeee;
}

.gap24 {
  gap: 24px;
}

.colorSecondary {
  color: #b3b3b3;
}

.margin-bottom12 {
  margin-bottom: 12px;
}

/* .less-data-pagination {
    position: absolute;
    bottom: 58px;
    left: 50%;
    transform: translate(-50%, 0);
  } */
.margin-bottom80 {
  margin-bottom: 80px;
}

.required::after {
  content: "*";
  color: red;
}

.colorSecondary5 {
  color: #293a3a;
}

.colorSecondary3 {
  color: #192323;
}

.colorSecondary6 {
  color: #677373;
}

.gap24 {
  gap: 24px;
}

.pointer-events-none {
  pointer-events: none;
}

.placeholderColorb8bdbd::placeholder {
  color: #b8bdbd;
  opacity: 1;
  /* Firefox */
}

.placeholderColorb8bdbd:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b8bdbd;
}

.placeholderColorb8bdbd::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b8bdbd;
}

.active-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  /* Accent/5 */

  background: #179778;
}

.toast-bottom-right .toast-error {
  background-color: #bd362f !important;
}

.toast-bottom-right .toast-info {
  background-color: #2f96b4 !important;
}

.toast-bottom-right .toast-success {
  background-color: #51a351 !important;
}

.toast-bottom-right .toast-warning {
  background-color: #f89406;
}

.scroll-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}

.z-index3 {
  z-index: 3 !important;
}

.z-index5 {
  z-index: 5 !important;
}

.line-height30 {
  line-height: 30px;
}

.line-height8 {
  line-height: 8px;
}

.line-height26 {
  line-height: 26px;
}

.font-size24 {
  font-size: 24px;
}

.font-size35 {
  font-size: 35px;
}

.font-size32 {
  font-size: 32px;
}

.font-size20 {
  font-size: 20px;
}

.screen-disable {
  pointer-events: none;
  opacity: 0.5;
}

.opacity-50 {
  opacity: 0.5;
}

.display-inline {
  display: inline;
}

.btn-disable-grey {
  background-color: #b8bdbd;
}


.text-underline {
  text-decoration-line: underline;
}

.margin-bottom10 {
  margin-bottom: 10px;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.break-word {
  word-break: break-word;
}

.hr-divider {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.width-fit-content {
  width: fit-content;
}

.width-max-content {
  width: max-content;
}

.height-fit-content {
  height: fit-content;
}

.margin-top2 {
  margin-top: 2px;
}


.height40px {
  height: 40px;
}

.cta-btn {
  background: #0e5b48;
  border-radius: 6px;
  padding: 12px 0;
  width: 100%;
  font-weight: 500;
  color: #fff;
}

.cta-btn:disabled {
  background: #b8bdbd;
}

.indicator .carousel-indicators {
  display: flex !important;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.indicator .carousel-indicators>li {
  width: 6px;
  height: 4px;
  border-radius: 20%/50%;
  background-color: #a9b0b0;
}

.indicator .carousel-indicators>li.active {
  background-color: #293a3a;
  width: 24px;
  height: 4px;
  border-radius: 8% 50%;
}

.padding-top1 {
  padding-top: 1px;
}

@font-face {
  font-family: HyundaiText-Bold;
  src: url("./HyundaiSansTextOffice-Bold.ttf") format("truetype");
}

.HyundaiText-Bold {
  font-family: HyundaiText-Bold;
}

@font-face {
  font-family: HyundaiText-Regular;
  src: url("./HyundaiSansTextOffice-Regular.ttf") format("truetype");
}

.HyundaiText-Regular {
  font-family: HyundaiText-Regular;
}

@font-face {
  font-family: HyundaiSansHead-Regular;
  src: url("./HyundaiSansHeadOffice-Regular.ttf") format("truetype");
}

.HyundaiSansHead-Regular {
  font-family: HyundaiSansHead-Regular;
}

@font-face {
  font-family: HyundaiSansHead-Medium;
  src: url("./HyundaiSansHeadOffice-Medium.ttf") format("truetype");
}

.HyundaiSansHead-Medium {
  font-family: HyundaiSansHead-Medium;
}
