/* #region Modals CSS */
.modal1 {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D6D6D6;
  padding: 20px;
  position: fixed;
  bottom: 2rem;
  z-index: 10;
  width: 54%;
  height: 13%;
}

.ticker-text {
  color: #aeaeae;
  display: block;
  font-size: 18px;
  line-height: 130%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
  height: 2em
}

.termlinks {
  opacity: .5;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 1rem;

  color: #000;
  cursor: pointer;
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 1px;
  transition: color .3s ease-out;
  font-family: HyundaiSansHead-Medium;
}

.transition-text {
  color: #002c5f;
  transition: opacity 0.4s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.ticker-text--kr {
  width: calc(100% - 230px)
}

.ticker-text--empty {
  opacity: 0
}

.create-button {
  background-color: #002c5f;
  padding: 10px;
  color: white;
  font-size: 18px;
  border: 1px solid transparent;
  font-family: HyundaiSansHead-Medium;
  transition: background-color 0.2s ease-out
}

.create-button:hover {
  background-color: #004489;
  transition: background-color 0.2s ease-in
}

.label {
  font-family: HyundaiSansHead-Medium;
  font-size: 14px;
}

/* .................................. */

.modal2 {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D6D6D6;
  padding: 25px 25px;
  position: fixed;
  bottom: 2rem;
  z-index: 10;
  width: 54%;
}

.edit {
  width: 3%;
}

.editPrompt {
  font-size: 18px;
  font-family: HyundaiSansHead-Medium;
}

.topbar-heads {
  margin: 0px 15px 0px 15px;
  font-size: 22px;
  font-family: HyundaiSansHead-Medium;
  cursor: pointer;
}

.topbar-heads.active {
  border-bottom: 2px solid #003984;
  color: #003984;
}

.input {
  height: 3em;
  width: 100%;
  outline: none;
  display: flex;
  resize: none;
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px solid transparent;
  font-family: HyundaiSansHead-Regular;
}

.disabled-button {
  background-color: #A9A9A9;
  cursor: not-allowed;
}

.common-button {
  width: fit-content;
  height: fit-content;
  display: flex;
  color: #a3a7af;
  align-items: center;
  border: 3px solid #a3a7af;
  border-radius: 40px;
  background-color: #ffffff;
  padding: 7px;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
}

.common-button.active {
  border-color: #003984;
  color: #003984;
}

.common-button:hover {
  background-color: #f6f3f2;
}

.prompt-card {
  background-color: #1995CB;
  width: 28%;
  padding: 20px;
  animation: prompt-card .25s;
}

@keyframes prompt-card {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.prompts-type {
  font-size: 21px;
  height: fit-content;
}

.prompts {
  padding: 4px;
  font-size: 16px;
  word-wrap: break-word;
  width: 100%;
  font-family: HyndaiText-Regular;
}

.prompts :hover {
  color: #002c5f
}

/* ................................ */

.loadingModal {
  display: flex;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D6D6D6;
  padding: 42px;
  position: fixed;
  bottom: 2rem;
  z-index: 10;
  width: 67.5%;
  height: 266px;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.magic-animation__icon {
  animation: blink 1.7s infinite;
}

.sparkle-image {
  transition: opacity 0.5s ease-in-out;
}

.sparkle-image:hover {
  animation: blink 1s infinite;
}

.errorModal {
  display: flex;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D6D6D6;

  position: fixed;
  bottom: 2rem;
  z-index: 10;
  width: 67.5%;
  height: 346px;
}

.textMargin {
  margin: 0px 95px 0px 95px;
}

/* ............................... */

.downloadModal {
  display: flex;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D6D6D6;
  padding: 42px;
  position: fixed;
  bottom: 2rem;
  z-index: 1000;
  height: 87%;
  width: 74.5%;
}

.imageControls {
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.finalPrompts {
  font-size: 20px;
  color: #000000;
  font-family: HyundaiSansHead-Medium;
}

/* ............................... */

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fullscreen-image {
  height: 100vh;
  width: 100%;
}

.fullscreen-image img {
  max-width: 100%;
  max-height: 100%;
}

.close-button img {
  width: 30px;
  height: 30px;
}

/* .................................. */

.previewscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.previewscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-color: hsla(0, 0%, 100%, .7);
  display: flex;
  z-index: 9999;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #002c5f;
}

.close {
  fill: #002c5f;
  transition: fill 0.2s ease-in-out;
}

.close:hover {
  fill: #11a7ca;
}

.copyPrompt {
  background-color: transparent;
  color: #11a7ca;
  font-size: 14px;
}

.copyPromptHovered path {
  fill: #11a7ca;
  stroke: #11a7ca;
  transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out;

}

.copyPromptHovered rect {
  fill: #11a7ca;
  stroke: #11a7ca;
  transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out;
}

.copyspan {
  color: #002c5f;
}

.copyspanHovered {
  color: #11a7ca;
  transition: color 0.2s ease-in-out;
}

/* .................................. */

.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--color-text);
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
}

.link--metis {
  font-family: bely-display, sans-serif;
  text-transform: lowercase;
}

.link--metis::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.link--metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

@media (max-width: 480px) {
  .label {
    font-size: 14px;
    font-family: HyundaiSansHead-Regular;
  }

  .edit {
    width: 6%;
  }

  .editPrompt {
    font-size: 15px;
  }

  .modal1 {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0px;
    width: 85%;
    height: 7%;
    flex-direction: column;
  }

  .modal2 {
    box-shadow: 0 4px 16px #002c5f59;
    width: 95%;
  }

  .prompt-card {
    width: 65%;
    word-wrap: break-word;
  }

  .input {
    width: 100%;
  }

  .create-button {
    font-size: 0.875rem;
    height: 100%;
  }

  .topbar-heads {
    font-size: 24px;
  }

  .finalPrompts {
    font-size: 16px;
  }

  .ticker-text {
    font-size: 12px;
    width: calc(100% - 0px)
  }


  .loadingModal {
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D6D6D6;
    padding: 42px;
    position: fixed;
    bottom: 3rem;
    z-index: 10;
    width: 95%;
  }

  .downloadModal {
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D6D6D6;
    padding: 8px;
    padding-top: 30px;
    position: fixed;
    bottom: 3rem;
    z-index: 10;
    width: 95%;
    height: 70%;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .modal1 {
    height: 10%;
    width: 85%;
  }

  .modal2 {
    width: 85%;
  }

  .loadingModal {
    width: 85%;
  }

  .downloadModal {
    width: 89%;
  }


  .edit {
    width: 4%;
  }

}
